onComplete(function () {
  const SCROLL_BUFFER = 64;
  let scanHandlers = [];
  const observeElement = function (element, module, collection, urlPrefix = null) {
    // Store state.
    let page = 1;
    let isFetching = false;
    const computedStyle = getComputedStyle(element);
    const isItselfScroll = computedStyle.getPropertyValue('overflow-y') === 'scroll';
    const scanForInView = function () {
      // Don't scan if we are busy fetching or have been culled.
      if (isFetching || scanHandlers.indexOf(scanForInView) < 0) {
        return;
      }

      // Check if bottom of element is in view.
      let shouldUpdate = false;
      if (isItselfScroll) {
        shouldUpdate = element.scrollHeight - element.scrollTop - element.clientHeight < SCROLL_BUFFER;
      } else {
        const rect = element.getBoundingClientRect();
        shouldUpdate = rect.bottom - window.innerHeight < SCROLL_BUFFER;
      }
      if (shouldUpdate) {
        // Mark as fetching.
        isFetching = true;

        // Increment page index.
        page++;

        // Fetch new page of data.
        const request = new XMLHttpRequest();
        let url = 'index.php?ajaxModal=true&moduleid=' + module + '&action=collection_page&collection=' + collection + '&page=' + page;
        if (urlPrefix) {
          url = urlPrefix + '/' + url;
        }
        request.open('GET', url, true);
        request.onload = function () {
          // Skip if we are not done fetching.
          if (request.readyState !== 4) {
            return;
          }

          // Stop if we have been culled.
          if (scanHandlers.indexOf(scanForInView) < 0) {
            element.removeEventListener('scroll', scanForInView);
            window.removeEventListener('scroll', scanForInView);
            return;
          }
          switch (request.status) {
            case 200:
              // Get response text.
              const response = request.responseText;
              if (!response || response.trim() === '') {
                // Stop scanning!
                element.removeEventListener('scroll', scanForInView);
                window.removeEventListener('scroll', scanForInView);
                return;
              }

              // Add response to the page.
              const elementTemp = document.createElement(element.nodeName.toLowerCase());
              elementTemp.innerHTML = response;
              element.append(...elementTemp.children);

              // Dispatch loaded event.
              element.dispatchEvent(new CustomEvent('blog-lazyloaded', {
                detail: {
                  collection: collection,
                  module: module,
                  page: page
                }
              }));

              // Mark as done and scan again to see if we are scrolled far enough for another round already.
              isFetching = false;
              scanForInView();
              break;
            default:
              // Silently fail.

              // Stop scanning!
              element.removeEventListener('scroll', scanForInView);
              window.removeEventListener('scroll', scanForInView);
              break;
          }
        };
        request.send();
      }
    };

    // Scan on scroll and scan now.
    element.addEventListener('scroll', scanForInView, {
      passive: true
    });
    window.addEventListener('scroll', scanForInView, {
      passive: true
    });
    return scanForInView;
  };
  const startObserving = function () {
    scanHandlers = [];
    // Check lazy prefix for backwards compatibility.
    const elements = document.querySelectorAll('[data-blog-collection],[data-lazy-blog-collection]');
    for (let i = 0; i < elements.length; i++) {
      const element = elements[i];
      let data = '';
      if (element.hasAttribute('data-blog-collection')) {
        data = element.getAttribute('data-blog-collection');
      } else {
        data = element.getAttribute('data-lazy-blog-collection');
      }
      data = data.split(',');
      const scanHandler = observeElement(element, data[0], data[1], data.length > 1 ? data[2] : null);
      scanHandlers.push(scanHandler);
      scanHandler();
    }
  };
  startObserving();
  if (window.editor) {
    window.editor.addEventListener('reloading', function () {
      // Stop current scanners.
      for (let i = 0; i < scanHandlers.length; i++) {
        window.removeEventListener('scroll', scanHandlers[i]);
      }
      scanHandlers = [];
    });
    window.editor.addEventListener('reloaded', function () {
      startObserving();
    });
  }
});